/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@font-face {
  font-family: "OpenSans"; //This is what we are going to call
  src: url("./assets/font/OpenSans-VariableFont_wdth,wght.ttf");
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: OpenSans !important;
  letter-spacing: -0.3px !important;
  box-sizing: border-box !important;
}

ion-button {
  text-transform: none !important;
}

.header-section {
  padding-top: 10% !important;
  height: 40vh !important;
}

.main-section {
  height: 75% !important;
}

.background-dark {
  background: #424551 !important;
  --background: #424551 !important;
}

.background-transparent {
  background: transparent !important;
  --background: transparent !important;
}

.background-success {
  background: #00c98d !important;
  --background: #00c98d !important;
}

.background-white {
  background: white !important;
  --background: white !important;
}

.background-grey {
  background: #f5f5f5 !important;
  --background: #f5f5f5 !important;
}

.background-active,
.background-secondary {
  background: #e6effa !important;
  --background: #e6effa !important;
}

.background-primary {
  background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
  --background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
}

.border-top {
  border-top: 1px solid #e7e7e7 !important;
}

.border-heavy-grey {
  --border: 1px solid #d5d5d5 !important;
  --border-width: 1px !important;
  --border-style: solid !important;
  --border-color: #d5d5d5 !important;
}

.border-danger {
  border: 1px solid #fe0002 !important;
  --border: 1px solid #fe0002 !important;
}

.border-white {
  border: 1px solid white !important;
}

.text-danger {
  color: #fe0002 !important;
  --color: #fe0002 !important;
}

.text-primary {
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

.text-grey {
  color: #999999 !important;
  --color: #999999 !important;
}

.text-dark {
  color: #222222 !important;
  --color: #222222 !important;
}

.text-black {
  color: black !important;
  --color: black !important;
}

.text-white {
  color: white !important;
  --color: white !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-bold {
  font-weight: bolder !important;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.no-letter-spacing {
  letter-spacing: 0px !important;
}

.card-rounded {
  border-radius: 20px !important;
  --border-radius: 20px !important;
}

.btn-rounded {
  border-radius: 25px !important;
  --border-radius: 25px !important;
}
.modal-rounded {
  border-radius: 30px !important;
  --border-radius: 30px !important;
}

.menu-rounded {
  border-radius: 10px !important;
  --border-radius: 10px !important;
}

.rounded {
  border-radius: 5px !important;
  --border-radius: 5px !important;
}

.rounded-up {
  border-radius: 40px 40px 0px 0px !important;
  --border-radius: 40px 40px 0px 0px !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.mh-100 {
  min-height: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.start-width {
  width: 14px !important;
  height: 14px !important;
}

.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-15px {
  width: 15px !important;
}

.w-15r {
  width: 1.5rem !important;
}

.w-2r {
  width: 2rem !important;
}

.point-4px {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50% !important;
}

.wh-3r {
  width: 3rem !important;
  height: 3rem !important;
}

.wh-8r {
  width: 8rem !important;
  height: 8rem !important;
}

.wh-375r {
  width: 3.75rem !important;
  height: 3.75rem !important;
}

.wh-55r {
  width: 5.5rem !important;
  height: 5.5rem !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.absolute-center {
  left: 50%;
  transform: translate(-50%);
}

.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-1 {
  bottom: 1rem !important;
}

.right-0 {
  right: 0px !important;
}

.right-1 {
  right: 1rem !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-05 {
  padding-left: 0.5rem !important;
}

.pr-05 {
  padding-right: 0.5rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pb-05 {
  padding-bottom: 0.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-025 {
  margin-top: 0.25rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.input-padding {
  padding: 0.25rem !important;
  padding-left: 1rem !important;
}

.fit-contain {
  object-fit: contain !important;
}

.no-ripple {
  --ripple-color: transparent !important;
  --background-activated: transparent !important;
  --background-hover: transparent !important;
}

.outline-none {
  outline: none !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.active:not(ion-item, ion-button),
.active::part(native) {
  border: 1px solid var(--ion-color-primary) !important;
}

.inactive,
.inactive::part(native) {
  border: none !important;
  background-color: #e8e8e8 !important;
}

ion-button.btn-padding::part(native) {
  padding: 1.5rem !important;
}

ion-button.btn-next::part(native) {
  padding: 1.5rem !important;
  box-shadow: 0px 10px 20px 0px #005dc94d;
  background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
  --background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
}

#cancel-button,
#confirm-button {
  box-shadow: 0px 10px 20px 0px #005dc94d;
  border-radius: 25px !important;
  --border-radius: 25px !important;
  width: 5.75rem;
  height: 3rem;
}

#cancel-button {
  background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
  --background: linear-gradient(
    347.96deg,
    #0000fe -100%,
    #005dc9 100%
  ) !important;
  color: white !important;
  --color: white !important;
  &::part(native) {
    color: white !important;
    --color: white !important;
  }
}

#cancel-button::part(native) {
  color: white !important;
  --color: white !important;
}

#confirm-button {
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

.loading-wrapper {
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
}

.sheet-modal {
  &::part(content) {
    --border-radius: 40px;
  }
}

ion-modal:not(.modal-sheet) {
  &::part(content) {
    max-width: 80% !important;
    margin: auto !important;
    background-color: transparent !important;
  }
}

.alert-wrapper {
  width: 80% !important;
  max-width: 80% !important;
  border-radius: 30px !important;
  --border-radius: 30px !important;
  .alert-title {
    color: #222222 !important;
    font-weight: 900 !important;
    font-size: 18px !important;
    text-align: center !important;
  }
  .alert-checkbox-label {
    white-space: pre-line !important;
    color: #222222 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .alert-checkbox-group {
    border-bottom: none !important;
    .alert-checkbox-icon {
      width: 20px !important;
      height: 20px !important;
      border-radius: 5px !important;
      border: 1px solid #005dc9 !important;
    }
  }
  [aria-checked="true"].sc-ion-alert-md .alert-checkbox-inner.sc-ion-alert-md {
    top: 3px !important;
    left: 6px !important;
  }
  [aria-checked="true"].sc-ion-alert-ios
    .alert-checkbox-inner.sc-ion-alert-ios {
    top: 2px !important;
    left: 7px !important;
  }
  .alert-button-group {
    margin-top: 1rem !important;
    align-self: center !important;
    padding-bottom: 1.5rem !important;
    justify-content: space-around !important;
    flex-direction: row !important;
    .alert-button {
      color: #222222 !important;
      font-weight: bold !important;
      font-size: 14px !important;
      border-radius: 25px !important;
      --border-radius: 25px !important;
      border: 1px solid #d5d5d5 !important;
      padding: 1rem !important;
      margin: none !important;
      min-width: 45% !important;
      max-width: 45% !important;
      .alert-button-inner {
        justify-content: center !important;
      }
    }
  }
}

.shadow-none {
  box-shadow: none !important;
}

.short-line {
  width: 20px !important;
  height: 1px !important;
  border: none !important;
  background-color: #222222;
}

.img-12 {
  width: 12px !important;
  height: 12px !important;
}

.img-16 {
  width: 16px !important;
  height: 16px !important;
}

.img-18 {
  width: 18px !important;
  height: 18px !important;
}

.img-20 {
  width: 20px !important;
  height: 20px !important;
}

.img-24 {
  width: 24px !important;
  height: 24px !important;
}

.img-26 {
  width: 26px !important;
  height: 26px !important;
}

ion-checkbox::part(container) {
  border-radius: 5px !important;
  border: 1px solid var(--ion-color-primary) !important;
}

/* Chrome, Safari, Edge, Opera  ---  remove arrows/spinners from input type number with CSS.  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

.select-gender {
  padding: 0.75rem 1rem 0.75rem 1rem !important;
}

ion-item {
  --highlight-color-valid: transparent !important;
  --highlight-color-invalid: transparent !important;
  --highlight-color-focused: var(--ion-color-light) !important;
}

.point {
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
}

.point.active {
  background-color: #ccdff4 !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
}

.text-step {
  color: #bbbbbb !important;
}

ion-item {
  --inner-padding-end: 0px !important;
  --padding-start: 0px !important;
}

ion-item::part(native) {
  padding-left: 0px !important;
}

.step-line {
  height: 1px !important;
  border: 1px solid #e7e7e7 !important;
  top: 9px !important;
  width: 75% !important;
  left: 12.5% !important;
  z-index: 0 !important;
}

.calendar-icon {
  z-index: 2 !important;
  right: 0.75rem !important;
}

.ios .calendar-icon {
  top: 2.75rem !important;
}

.md .calendar-icon {
  top: 2.25rem !important;
}
